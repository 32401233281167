/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel {
    display: inline-flex;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; 
    -ms-overflow-style: none;
  }